import axios from 'axios'
import React, { useContext, useState } from 'react'
import { AppContext } from '../App'
import { CopyAll } from '@mui/icons-material'
import { sha256 } from 'js-sha256'
import DataTable from 'react-data-table-component'
import moment from 'moment'

export default function DisputeTransaction() {
  const [orderId, setOrderId] = useState("")
  const [transaction, setTransaction] = useState([])
  const [error, setError] = useState("")
  const context = useContext(AppContext)

  function getTableData(table) {
    let text = '';
    const rows = table.querySelectorAll('tr');

    // Iterate through rows and cells
    for (const row of rows) {
      const cells = row.querySelectorAll('th, td');
      for (const cell of cells) {
        text += cell.textContent.trim() + '\t'; // Use tabs for separation
      }
      text += '\n'; // Add newline after each row
    }

    return text;
  }

  function copytable(el) {
    const table = document.getElementById('table');
    const text = getTableData(table)
    navigator.clipboard.writeText(text).then(() => {
      alert('Table data copied to clipboard!');
    })
      .catch(err => {
        alert('Failed to copy table data');
        // Handle clipboard permission errors or other issues (optional)
      });
  }

  async function fetchDisputeTransaction(event) {
    event.preventDefault()
    const [, clientIdString] = context.nmid?.split(":") ?? ["", ""]
    const timestamp = `${new Date().getTime()}`
    const headers = {
      "order_id": orderId,
      "client_id": clientIdString,
      "Authorization": "Basic ZGFzaDokJSREJEFTSA==",
      "x-signature": sha256(`${timestamp}:${"p1Ro"}`),
      "x-timestamp": timestamp
    }

    try {
      setError("")
      setTransaction([])

      const url = "https://payloadcms.piro.my.id/dispute-transaction"
      const transactionResponse = await axios.post(url, {
          "orderId": [orderId]
      },{
        headers
      })
      const transactions = await axios.post("https://payloadcms.piro.my.id/get-dispute-transaction",{
        headers
      })
      alert("Berhasil silahkan lihat list dibawah")
      setTransaction(transactions.data.data)
    } catch (error) {
      setError("Order Id tidak ditemukan")
    }
  }

  return (
    <div style={{ padding: "20px" }}>
      <h2>Dispute Transactions</h2>
      <div>
        <form onSubmit={fetchDisputeTransaction}>
          <label>Order ID</label>
          <div style={{ display: "flex" }}>
            <input
              className=''
              name="orderId"
              type='text'
              placeholder='Input order Id'
              value={orderId}
              style={{ border: "1px solid black" }}
              onChange={(event) => setOrderId(event.target.value)}
            />
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='button' onClick={e => {
              // setTransaction([])
              setOrderId("")
            }}>Clear</button>
            <button style={{ padding: "0px 10px 0px 10px", marginLeft: "10px" }} type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <hr></hr>
      <p style={{ textAlign: "center" }}>atau upload order id, (max 50 row)</p>
      <hr></hr>
      <input type='file'></input>
      <div style={{ marginTop: "40px", display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
        {error && (
          <p className='my-4'>{error}</p>
        )}
        {console.log(transaction)}

        <DataTable
          columns={[
            {
              name: 'Transaction Date',
              selector: row => moment(row.trxAt).format("DD-MM-YYYY HH:mm:ss"),
              width: "175px",
              style: {
                color: 'rgba(0,0,0,.54)',
              },
            },
            {
              name: 'Order Id',
              selector: row => row.orderId,
              width: "600px",
              style: {
                color: 'rgba(0,0,0,.54)',
              },
            },
            // {
            //     name: 'Bank Tujuan',
            //     selector: row => row.data.attachment?.inquiry?.beneficiaryBankName,
            //     width: "100px",
            //     style: {
            //         color: 'rgba(0,0,0,.54)',
            //     },
            // },
            // {
            //     name: 'Nomor rekening',
            //     selector: row => row.data.attachment?.inquiry?.beneficiaryAccountNumber,
            //     width: "100px",
            //     style: {
            //         color: 'rgba(0,0,0,.54)',
            //     },
            // },
            {
              name: 'Nominal',
              selector: row => row.nominal,
              width: "150px",
              style: {
                color: 'rgba(0,0,0,.54)',
              },
            },
            {
              name: 'Status',
              selector: row => row.status,
              width: "490px",
              style: {
                color: 'rgba(0,0,0,.54)',
              },
            },
          ]}
          data={transaction}
          // customStyles={customStyles}
          highlightOnHover
          pointerOnHover
        />
      </div>
    </div>
  )
}
