import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../App'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import DataTable from 'react-data-table-component';
import moment from "moment"
import { url } from '../BaseUrl';
import { ButtonBase } from '@mui/material';

export default function ReportRefund() {
    const [orderId, setOrderId] = useState("")
    const [transaction, setTransaction] = useState([])
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [error, setError] = useState("")
    const context = useContext(AppContext);
    let [nmid, clientId] = context.nmid.split(":")
    const [lastCapture, setLastCapture] = useState(moment()); // Tanggal capture terakhir, dimulai dari sekarang

    useEffect(() => {
        const interval = setInterval(() => {
            setLastCapture(moment()); // Perbarui tanggal capture setiap 5 menit
        }, 300000); // 5 menit dalam milidetik

        return () => clearInterval(interval); // Bersihkan interval saat komponen di-unmount
    }, []);

    const formatLastCapture = () => {
        return lastCapture.format('DD MMMM YYYY, HH:mm:ss'); // Format tanggal sesuai keinginan Anda
    };

    const timeSinceLastCapture = () => {
        const now = moment();
        const diff = now.diff(lastCapture);
        const duration = moment.duration(diff);
        return duration.humanize();
    }

    async function recursive(nextToken, list = [], day, UID) {
        if (!nextToken) return list
        const response = await axios.get(`https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/current-accounts-settled-page?uid=${UID}&specific_day=${day}&limit=1000&nextPageToken=${nextToken}`, {
            headers: {
                Authorization: "Basic ZGFzaDokJSREJEFTSA=="
            }
        })
        console.log(nextToken, "nexttToken")
        list = [...list, ...response.data.list]
        return recursive(response.data.nextPageToken, list, day, UID)
    }

    async function fetchCheckStatus(event) {
        console.log("masuk")
        event.preventDefault()
        startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
        const day = startDate.toISOString().split('T')[0]
        // let uri = `https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/current-accounts-settled-page?uid=${"hPwdJ9IsWQzpMs8Y8cBe"}&specific_day=${}&limit=1000`
        try {
            clientId = "hPwdJ9IsWQzpMs8Y8cBe"
            let headers = {
                authorization: "Basic ZGFzaDokJSREJEFTSA=="
            }
            const response = await axios.get(`https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/current-accounts-settled-page?uid=${clientId}&specific_day=${day}&limit=1000`, { headers })
            const auditReconcile = await recursive(response.data.nextPageToken, response.data.list, day, clientId)
            setError("")
            setTransaction([])

    
            const filtered = auditReconcile.filter(e => e.id.includes("REFUND"))

            // console.log(transactionResponse.data.list
            setTransaction(filtered)
        } catch (error) {
            console.log(error)
            setError("Order Id tidak ditemukan")
        }
    }


    return (
        <div style={{ padding: "20px", width: "100%" }}>
            <h2>Refund</h2>
            <div style={{ fontSize: "12px", color: "green" }}>
                <p>Tanggal Capture Terakhir: {formatLastCapture()}</p>
                <p>Terakhir Capture: {timeSinceLastCapture()}</p>
            </div>
            {/* <i style={{ fontSize: "12px", color: "red" }}>Refund</i> */}
            <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between" }}>
                <form onSubmit={fetchCheckStatus}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker label="Start Date" format="DD-MM-YYYY" onChange={e => setStartDate(moment(e).toDate())} />
                            </LocalizationProvider>
                            {/* <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker label="End Date" format="DD-MM-YYYY" onChange={e => setEndDate(moment(e).toDate())}/>
                        </LocalizationProvider> */}
                        </div>

                        <button style={{ padding: "0px 30px 0px 30px", marginTop: "20px", marginLeft: "10px", height: "53px" }} type='submit'>Submit</button>
                    </div>
                </form>
            </div>
            <DataTable
                columns={[
                    {
                        name: 'Transaction Date',
                        selector: row => moment(row.metadata.createdDate).format("DD-MM-YYYY HH:mm:ss"),
                        width: "175px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'TX',
                        selector: row => row.id,
                        width: "600px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    // {
                    //     name: 'Bank Tujuan',
                    //     selector: row => row.data.attachment?.inquiry?.beneficiaryBankName,
                    //     width: "100px",
                    //     style: {
                    //         color: 'rgba(0,0,0,.54)',
                    //     },
                    // },
                    // {
                    //     name: 'Nomor rekening',
                    //     selector: row => row.data.attachment?.inquiry?.beneficiaryAccountNumber,
                    //     width: "100px",
                    //     style: {
                    //         color: 'rgba(0,0,0,.54)',
                    //     },
                    // },
                    {
                        name: 'Nominal',
                        selector: row => row.data.credit,
                        width: "150px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    {
                        name: 'Status',
                        selector: row => "REFUNDED",
                        width: "490px",
                        style: {
                            color: 'rgba(0,0,0,.54)',
                        },
                    },
                    // {
                    //     name: 'Type',
                    //     selector: row => row.type,
                    //     style: {
                    //         color: 'rgba(0,0,0,.54)',
                    //     },
                    // },
                    // {
                    //     name: 'Order ID',
                    //     // selector: row => row?.piroBase?.transactionRequest?.trigger?.data?.orderId ?? row?.piroBase?.transactionId,
                    //     selector: row => row.type == "RTOL" ? row?.piroBase?.transactionRequest?.partnerReferenceNo : row?.piroBase?.transactionId,
                    //     // selector: row => row?.piroBase?.transactionRequest?.partnerReferenceNo?.originalReferenceNo,
                    //     width: "335px",
                    //     style: {
                    //         color: 'rgba(0,0,0,.54)',
                    //     },
                    // },
                    // {
                    //     name: 'Debit',
                    //     selector: row => !row.piroBase.debit ? <span>Rp 0</span> : <span style={{ color: "red" }}>{row.piroBase.debit.toLocaleString(
                    //         "id-ID",
                    //         { style: "currency", currency: "IDR" }
                    //     )}</span>,
                    //     style: {
                    //         color: 'rgba(0,0,0,.54)',
                    //     },
                    // },
                    // {
                    //     name: 'Credit',
                    //     selector: row => !row.piroBase.credit ? <span>Rp 0</span> : <span style={{ color: "green" }}>{row.piroBase.credit.toLocaleString(
                    //         "id-ID",
                    //         { style: "currency", currency: "IDR" }
                    //     )}</span>,
                    //     style: {
                    //         color: 'rgba(0,0,0,.54)',
                    //     },
                    // },
                ]}
                data={transaction}
                // customStyles={customStyles}
                highlightOnHover
                pointerOnHover
            // theme='solarized'
            />
        </div>
    )
}
