import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import DataTable from 'react-data-table-component';
import { AppContext } from '../../App';
import CustomMaterialPagination from '../../components/CustomPagination'
import { urlCloudFunction } from '../../BaseUrl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, FormControl, Input, InputLabel, MenuItem, Select, SvgIcon, TextField } from '@mui/material';
import { Option, styled } from '@mui/joy';
import { CheckCircleOutline, Cloud, Upload } from '@mui/icons-material';
import { sha256 } from 'js-sha256';

const customStyles = {
  headRow: {
    style: {
      border: 'none',
    },
  },
  headCells: {
    style: {
      color: '#202124',
      fontSize: '14px',
    },
  },
  rows: {
    highlightOnHoverStyle: {
      backgroundColor: 'rgb(230, 244, 244)',
      borderBottomColor: '#FFFFFF',
      borderRadius: '25px',
      outline: '1px solid #FFFFFF',
    },
  },
  pagination: {
    style: {
      border: 'none',
    },
  },
};


export const Workspaces = () => {
  const [bank, setbank] = useState("014")
  const [step, setStep] = useState(0)
  const [form, setForm] = useState(
    {
      "callbackUrl": "",
      "collection": "piro_user",
      "banksAccount": {
        "accountName": "",
        "accountNumber": "",
        "bankBICode": "",
        "bankName": "",
        "callbackClient": ""
      },
      "email": "",
      "mobile": "",
      "username": "",
    }
  )
  const context = useContext(AppContext)
  const [files, setFiles] = useState()
  const [loading, setLoading] = useState(true)

  const columns = [
    {
      cell: () => <svg
        fill="#43A047"
        aria-hidden="true"
        className="MuiSvgIcon-root"
        viewBox="0 0 24 24"
      >
        <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z"></path>
      </svg>,
      width: '56px', // custom width for icon button
      style: {
        borderBottom: '1px solid #FFFFFF',
        marginBottom: '-1px',
      },
    },
    {
      name: 'Alias',
      selector: row => row.setAlias,
      sortable: true,
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    {
      name: 'Client id',
      selector: row => row.clientId,
      sortable: true,
      width: "400px",
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    {
      name: 'Environment',
      selector: row => row.envrontment == "dana" ?
        <span style={{ backgroundColor: "purple", color: "white", padding: "10px 20px", borderRadius: "50px" }}>Dana</span> :
        <span style={{ backgroundColor: "orange", color: "white", padding: "10px 20px", borderRadius: "50px" }}>Nobu</span>,
      sortable: true,
      width: "150px",
      style: {
        color: 'rgba(0,0,0,.54)',
      },
    },
    // {
    //   name: 'Settled',
    //   selector: row => row.settled ? row.settled.toLocaleString(
    //     "id-ID",
    //     { style: "currency", currency: "IDR" }
    //   ) : 0,
    //   sortable: true,
    //   style: {
    //     color: 'rgba(0,0,0,.54)',
    //   },
    // },
    // {
    //   name: 'Unsettled',
    //   selector: row => row.unsettled ? row.unsettled.toLocaleString(
    //     "id-ID",
    //     { style: "currency", currency: "IDR" }
    //   ) : 0,
    //   sortable: true,
    //   style: {
    //     color: 'rgba(0,0,0,.54)',
    //   },
    // },
  ];

  const getBalance = async (clientId, nmid) => {
    const response = await axios.get(`${urlCloudFunction}`, {
      headers: {
        'client_Id': clientId,
        'nobuNMID': nmid,
        'Authorization': 'Basic ZGFzaDokJSREJEFTSA=='
      }
    })
    return response.data
  }

  const callbackFetch = useCallback(
    async () => {
      setLoading(true)
      const nmid = context?.auth?.user?.nmid
      const newFiles = await Promise.all(nmid.map(async e => {
        let balance = {}
        if (context?.auth?.user.email == "ritzcarlton@gmail.com") {
          balance = await getBalance(e.clientId, e.nmid)
        }
        return {
          ...e,
          settled: balance?.current_account?.settled?.balance ?? 0,
          unsettled: balance?.current_account?.unsettled?.balance ?? 0
        }
      }))
      console.log(newFiles)
      setFiles(newFiles)
      setLoading(false)
    },
    [],
  )

  const useEffectOnce = effect => {
    const destroyFunc = useRef();
    const calledOnce = useRef(false);
    const renderAfterCalled = useRef(false);

    if (calledOnce.current) {
      renderAfterCalled.current = true;
    }

    useEffect(() => {
      if (calledOnce.current) {
        return;
      }

      calledOnce.current = true;
      destroyFunc.current = effect();

      return () => {
        if (!renderAfterCalled.current) {
          return;
        }

        if (destroyFunc.current) {
          destroyFunc.current();
        }
      };
    }, []);
  }

  useEffectOnce(() => {
    callbackFetch()
  }, [])

  const onRowClicked = (e) => {
    window.open(`/qris?nmid=${e.nmid}&clientId=${e.clientId}&alias=${e.setAlias}`, '_blank')
  }

  const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

  const daftar = async () => {

    let data = {
      "callbackUrl": "https://payloadcms.piro.id/callback",
      "collection": "piro_user",
      "accountName": "Toto Rubianto",
      "accountNumber": "083108222625",
      "bankBICode": "008",
      "bankName": "Mandiri",
      "callbackClient": "https://payloadcms.piro.id/callback",
      "email": "toto.rubianto.devs@gmail.com",
      "mobile": "08170060097",
      "username": "totorubiantodevs",
    };

    const timestamp = `${new Date().getTime()}`
    await axios.post("https://us-central1-doc-basic.cloudfunctions.net/dashboard2gen/v1/user-create", data, {
      headers: {
        Authorization: "Basic ZGFzaDokJSREJEFTSA==",
        "x-signature": sha256(`${timestamp}:${"p1Ro"}`),
        "x-timestamp": timestamp
      }
    })
    setStep(1)
  }
  return (
    <div className="home" style={{ width: '100%', marginLeft: '2vw' }}>
      <div style={{ marginTop: "50px", marginBottom: "30px", textAlign: "center" }}>
        <p style={{ fontSize: "40px", fontWeight: "bold" }}>Selamat Datang di piro.id</p>
        <p style={{ fontSize: "20px" }}>Daftar Bisnis</p>
      </div>
      {loading ? (
        <div className='loading'>Loading</div>
      ) : (
        <>
          {files.length > 0 ? (
            <>
              <div className='table' style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", maxWidth: "1200px", margin: "auto" }}>
                <DataTable
                  columns={columns}
                  data={files}
                  customStyles={customStyles}
                  highlightOnHover
                  pointerOnHover
                  // pagination
                  onRowClicked={onRowClicked}
                // paginationComponent={() => <CustomMaterialPagination />}
                // theme='solarized'
                />
              </div>
              {files?.length === 0 ?
                (<p style={{ margin: 'auto', width: 'fit-content', marginTop: '5vh', fontSize: '13.75px' }}>Nothing to see here</p>) : (<></>)}
            </>
          ) : (
            <>
              {step == 0 ? (
                <form onSubmit={daftar}>
                  <div style={{ maxWidth: "700px", margin: "auto" }}>
                    <title>Pendaftaran</title>
                    <div>
                      <div style={{ marginBottom: "30px" }}>
                        <label style={{ fontWeight: "700" }}>Nama Lengkap sesuai KTP</label>
                        <TextField value={form.banksAccount.accountName} style={{ width: "100%", marginTop: "10px" }} id="outlined-basic" label="Nama Lengkap" variant="outlined" />
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <label style={{ fontWeight: "700" }}>Nama Bank</label>
                        <FormControl fullWidth style={{ marginTop: "10px" }}>
                          {/* <InputLabel id="demo-simple-select-label">pilih bank</InputLabel> */}
                          <Select
                            // labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={form.bankBICode}
                            label="Age"
                            onChange={(e) => setForm({ ...form })}
                          >
                            <MenuItem value={"014"}>BCA</MenuItem>
                            <MenuItem value={"008"}>Mandiri</MenuItem>
                            <MenuItem value={"002"}>BRI</MenuItem>
                            <MenuItem value={"503"}>NOBU</MenuItem>
                            <MenuItem value={"022"}>CIMB</MenuItem>
                            <MenuItem value={"009"}>BNI</MenuItem>
                          </Select>
                        </FormControl>
                        {/* <TextField style={{ width: "100%" }} id="outlined-basic" label="Bank" variant="outlined" /> */}
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <label style={{ fontWeight: "700" }} onChange={e=> setForm({ ...form, banksAccount: e.target.value })}>Nomor Rekening</label>
                        <TextField style={{ width: "100%", marginTop: "10px" }} id="outlined-basic" label="Pastikan no rek benar" variant="outlined" />
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <label style={{ fontWeight: "700" }}>Nomor Handphone</label>
                        <TextField style={{ width: "100%", marginTop: "10px" }} id="outlined-basic"  label="Masukan no HP" onChange={e=> setForm({ ...form, mobile: e.target.value })} variant="outlined" />
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startDecorator={
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                            </SvgIcon>
                          }
                        >
                          <Upload></Upload>
                          Upload Foto KTP
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => console.log(event.target.files)}
                            multiple
                          />
                        </Button>
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startDecorator={
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                            </SvgIcon>
                          }
                        >
                          <Upload></Upload>
                          Upload Foto Selfie
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => console.log(event.target.files)}
                            multiple
                          />
                        </Button>
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startDecorator={
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                            </SvgIcon>
                          }
                        >
                          <Upload></Upload>
                          Upload NIB
                          <VisuallyHiddenInput
                            type="file"
                            onChange={(event) => console.log(event.target.files)}
                            multiple
                          />
                        </Button>
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <TextField style={{ width: "100%" }} id="outlined-basic" label="NPWP" variant="outlined" />
                      </div>
                      <div style={{ marginBottom: "30px" }}>
                        <TextField style={{ width: "100%" }} id="outlined-basic" label="Callback" variant="outlined" />
                      </div>
                    </div>
                    <div className='btn-email'>
                      <Button type='submit' variant='contained' size='large'>Daftar</Button>
                    </div>
                  </div>
                </form>
              ) : (
                <>
                  <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
                    Permohonan anda sedang di proses, mohon untuk menunggu 3x24 jam kerja
                  </Alert>
                </>
              )}

            </>
          )}
        </>
      )}
    </div>
  )
}
