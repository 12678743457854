import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import Info from '@mui/icons-material/Info';
import '../css/leftbar.css'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Select, TextField } from '@mui/material';
import { AppContext } from '../App'
import 'react-tooltip/dist/react-tooltip.css'
import { AddCardRounded, CheckBoxSharp, ExpandLess, ExpandMore, FormatListBulleted, QrCode, Send } from '@mui/icons-material';
import { LinkWithQuery } from '../BaseUrl';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export const Leftbar = () => {
    const context = useContext(AppContext)
    const { nmid } = context
    const [anchorEl, setAnchorEl] = useState(null);
    const [openQris, setOpenQris] = useState(false);
    const [openQrisDana, setOpenQrisDana] = useState(false);
    const [openQrisNobu, setOpenQrisNobu] = useState(false);
    const [openBiFast, setOpenBiFast] = useState(false);
    const [openRtol, setOpenRtol] = useState(false);
    const [openVA, setOpenVA] = useState(false);
    const [openFlip, setOpenFlip] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const paramNmid = urlParams.get('nmid');
    const paramClientId = urlParams.get('clientId');
    const paramAlias = urlParams.get('alias');

    const getBalance = useCallback(
        () => {
            if (nmid) {
                const [nmidString, clientIdString] = nmid.split(":")
                context.getBalance(clientIdString, nmidString)
            }
        },
        // eslint-disable-next-line
        [nmid],
    )


    useEffect(() => {
        // getBalance()
    }, [nmid, getBalance])

    const handleOpenQris = () => {
        setOpenQris(!openQris)
    }
    const handleOpenQrisDana = () => {
        setOpenQrisDana(!openQrisDana)
    }
    const handleOpenQrisNobu = () => {
        setOpenQrisNobu(!openQrisNobu)
    }
    const handleBiFast = () => {
        setOpenBiFast(!openBiFast)
    }
    const handleRtol = () => {
        setOpenRtol(!openRtol)
    }
    const handleVA = () => {
        setOpenVA(!openVA)
    }
    const handleFlip = () => {
        setOpenFlip(!openFlip)
    }
    const handleChange = (e) => {
        context.setNmid(e.target.value)
    }

    const customRouteForEmail = [
        "dhcs01@piro.my.id",
        "dhcs02@piro.my.id",
        "dhcs03@piro.my.id",
        "dhcs04@piro.my.id",
        "dhcs05@piro.my.id",
        "hrzcs01@piro.my.id",
        "hrzcs02@piro.my.id",
        "hrzcs03@piro.my.id",
    ]

    return (
        <div className='leftbar'>

            {/* {context?.auth?.user?.balanceSettled && (
                <>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            "display": "flex",
                        }}
                    >
                        <MenuItem sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontFamily: 'Comic Neue' }}
                            onClick={() => { handleClickOpenDialog(); handleClose() }}

                        >
                            <CreateNewFolderIcon style={{ width: '21px', height: '20px', marginRight: '15px' }} /><p>Create Complain</p>
                        </MenuItem>
                        <input type="file" id="fileup" onChange={(e) => { e.preventDefault(); handleUpdate(e) }} hidden />
                        <label htmlFor="fileup">
                            <MenuItem sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', fontFamily: 'Comic Neue' }} >
                                <UploadFileIcon style={{ width: '21px', height: '20px', marginRight: '15px' }} /><p>View Complain</p>
                            </MenuItem>
                        </label>
                    </Menu>
                    <Dialog fullWidth open={openDialog} onClose={handleCloseDialog}>
                        <DialogTitle sx={{ fontFamily: 'Roboto Flex' }}><p style={{ fontFamily: 'Roboto Flex' }}>Complain</p></DialogTitle>
                        <DialogContent>
                            <DialogContentText></DialogContentText>
                            <TextField
                                InputProps={{ style: { fontSize: 13.9, fontFamily: 'Roboto Flex' } }}
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Description"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={filenameinput}
                                onChange={(e) => setFilenameinput(e.target.value)}
                            />
                        </DialogContent>
                        <DialogActions>

                            <button onClick={handleCloseDialog} style={{ width: '18%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: context.dark ? "rgba(255, 255, 255, 0.5)" : '#F0F5FF', outline: 'none', border: 'none', height: '39px', borderRadius: '32px', marginLeft: '22px', cursor: 'pointer', marginBottom: '17px', marginTop: '15px', color: 'inherit' }}><p>Cancel</p></button>
                            <button onClick={fileCreateHandle} style={{ width: '18%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: context.dark ? "rgba(255, 255, 255, 0.5)" : '#F0F5FF', outline: 'none', border: 'none', height: '39px', borderRadius: '32px', marginLeft: '22px', cursor: 'pointer', marginBottom: '17px', marginTop: '15px', color: 'inherit', marginRight: '9px' }}><p>Create</p></button>
                        </DialogActions>
                    </Dialog>
                    <div style={{ padding: "20px" }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label" style={{ backgroundColor: "white" }}>Select NMID</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={context.nmid}
                                label="Age"
                                onChange={handleChange}
                            >
                                {context?.auth && context?.auth?.user?.nmid.map((value, key) => (
                                    <MenuItem key={key} value={`${value.nmid}:${value.clientId}:${value.setAlias}`}>{value.nmid} - {value.setAlias}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>

                    <hr style={{
                        border: "0",
                        borderTop: "0.5px solid #ccc"
                    }}></hr>
                    <div style={{ padding: "10px 20px" }}>
                        <a
                            data-tooltip-id="my-tooltip"
                            href='#/'
                            data-tooltip-content="Settled balance adalah nominal yang dapat digunakan melalui BI Fast atau metode transfer lain secara API. Bila terdapat settled balance sampai jam cut off dari PIRO akan di transfer pada rekening default"
                            style={{ textDecoration: "none", color: "black" }}
                        >
                            <h4>Settled <Info fontSize='25px'></Info></h4>
                        </a>

                        <Tooltip className='z-100' id="my-tooltip" content="Settled balance adalah nominal yang dapat digunakan melalui BI Fast atau metode transfer lain secara API. Bila terdapat settled balance sampai jam cut off dari PIRO akan di transfer pada rekening default">
                        </Tooltip>

                        {context.balance?.current_account?.settled?.balance ? (
                            <h5>
                                {context.balance?.current_account?.settled?.balance?.toLocaleString(
                                    "id-ID",
                                    { style: "currency", currency: "IDR" }
                                )}
                            </h5>
                        ) :
                            (<h5>0</h5>)
                        }
                    </div>
                </>

            )} */}
            <div style={{ padding: "10px 20px" }}>
                {paramAlias} - {paramNmid}
            </div>
            <div style={{ padding: "10px 20px" }}>
                {paramClientId}
            </div>

            {/* {context?.auth?.user?.balanceSettled && (
                <div style={{ padding: "10px 20px" }}>
                    <a
                        data-tooltip-id="my-tooltip"
                        href='#/'
                        data-tooltip-content="Settled balance adalah nominal yang dapat digunakan melalui BI Fast atau metode transfer lain secara API. Bila terdapat settled balance sampai jam cut off dari PIRO akan di transfer pada rekening default"
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <h4>Settled <Info fontSize='25px'></Info></h4>
                    </a>

                    <Tooltip className='z-100' id="my-tooltip" content="Settled balance adalah nominal yang dapat digunakan melalui BI Fast atau metode transfer lain secara API. Bila terdapat settled balance sampai jam cut off dari PIRO akan di transfer pada rekening default">
                    </Tooltip>

                    {context.balance?.current_account?.settled?.balance ? (
                        <h5>
                            {context.balance?.current_account?.settled?.balance?.toLocaleString(
                                "id-ID",
                                { style: "currency", currency: "IDR" }
                            )}
                        </h5>
                    ) :
                        (<h5>0</h5>)
                    }
                </div>
            )} */}

            {/* {context?.auth?.user?.balanceUnsettled && (
                <div style={{ padding: "10px 20px" }}>
                    <a
                        data-tooltip-id="my-tooltip"
                        href='#/'
                        data-tooltip-content="Unsettled adalah transaksi yang telah sukses terjadi namun membutuhkan waktu distribusi dari pihak ke 3 diluar Piro. Setelah diterima Piro akan dipindahkan ke Settled setelah dikurangi biaya yang muncul."
                        style={{ textDecoration: "none", color: "black" }}
                    >
                        <h4>Unsettled<Info fontSize='25px'></Info></h4>
                    </a>

                    <Tooltip className='z-100' id="my-tooltip" content="Unsettled adalah transaksi yang telah sukses terjadi namun membutuhkan waktu distribusi dari pihak ke 3 diluar Piro. Setelah diterima Piro akan dipindahkan ke Settled setelah dikurangi biaya yang muncul.">
                    </Tooltip>

                    {context.balance?.current_account?.unsettled?.balance ? (
                        <h5>
                            {context.balance?.current_account?.unsettled?.balance?.toLocaleString(
                                "id-ID",
                                { style: "currency", currency: "IDR" }
                            )}
                        </h5>
                    ) :
                        (<h5>0</h5>)
                    }
                </div>
            )} */}

            <hr style={{
                border: "0",
                borderTop: "0.5px solid #ccc"
            }}></hr>
            {
                context.auth.user.nmid && context.auth.user.nmid.length > 0 ? customRouteForEmail.includes(context.auth.user.email) ? (
                    <>
                        <ListItemButton onClick={handleOpenQris}>
                            <ListItemIcon>
                                <QrCode />
                            </ListItemIcon>
                            <ListItemText primary="QRIS" />
                            {openQris ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openQris} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/check-status">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <CheckBoxSharp />
                                        </ListItemIcon>
                                        {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                        Check Status
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/check-status-snap">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <QrCode />
                                        </ListItemIcon>
                                        {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                        Check Status SNAP
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/check-status-rrn">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <QrCode />
                                        </ListItemIcon>
                                        Check based RRN SNAP
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                        </Collapse>
                    </>
                ) : (
                    <>
                        <LinkWithQuery className='text-plain' to="/topup">
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddCardRounded />
                                </ListItemIcon>
                                <ListItemText primary="Top Up" />
                            </ListItemButton>
                        </LinkWithQuery>
                        <ListItemButton onClick={handleOpenQris}>
                            <ListItemIcon>
                                <QrCode />
                            </ListItemIcon>
                            <ListItemText primary="QRIS" />
                            {openQris ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openQris} timeout="auto" unmountOnExit>
                            <List component="div" sx={{ pl: 4 }} disablePadding>
                                <ListItemButton onClick={handleOpenQrisDana}>
                                    <ListItemIcon>
                                        <QrCode />
                                    </ListItemIcon>
                                    <ListItemText primary="Dana" />
                                    {openQrisDana ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openQrisDana} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                        <LinkWithQuery className='text-plain' to="/check-status-dana">
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <CheckBoxSharp />
                                                </ListItemIcon>
                                                {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                                Check Status
                                            </ListItemButton>
                                        </LinkWithQuery>
                                    </List>
                                    <List component="div" disablePadding>
                                        <LinkWithQuery className='text-plain' to="/check-status-dana-tx">
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <CheckBoxSharp />
                                                </ListItemIcon>
                                                {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                                Check By TX
                                            </ListItemButton>
                                        </LinkWithQuery>
                                    </List>
                                </Collapse>
                                <ListItemButton onClick={handleOpenQrisNobu}>
                                    <ListItemIcon>
                                        <QrCode />
                                    </ListItemIcon>
                                    <ListItemText primary="Nobu" />
                                    {openQrisNobu ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openQrisNobu} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <LinkWithQuery className='text-plain' to="/check-status">
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <CheckBoxSharp />
                                                </ListItemIcon>
                                                {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                                Check Status
                                            </ListItemButton>
                                        </LinkWithQuery>
                                    </List>
                                    <List component="div" disablePadding>
                                        <LinkWithQuery className='text-plain' to="/check-status-snap">
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <QrCode />
                                                </ListItemIcon>
                                                {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                                Check Status SNAP
                                            </ListItemButton>
                                        </LinkWithQuery>
                                    </List>
                                    <List component="div" disablePadding>
                                        <LinkWithQuery className='text-plain' to="/check-status-rrn">
                                            <ListItemButton sx={{ pl: 4 }}>
                                                <ListItemIcon>
                                                    <QrCode />
                                                </ListItemIcon>
                                                Check based RRN SNAP
                                            </ListItemButton>
                                        </LinkWithQuery>
                                    </List>
                                </Collapse>
                            </List>

                            {/* <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/qris">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FormatListBulleted />
                                        </ListItemIcon>
                                        List Transaksi
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List> */}
                            {/* <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/check-status">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <CheckBoxSharp />
                                        </ListItemIcon>
                                        Check Status
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List> */}
                        </Collapse>
                        <ListItemButton onClick={handleBiFast}>
                            <ListItemIcon>
                                <Send />
                            </ListItemIcon>
                            <ListItemText primary="BI Fast" />
                            {openBiFast ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openBiFast} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/bi-fast">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FormatListBulleted />
                                        </ListItemIcon>
                                        List Transaksi
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/check-bifast-ref-id">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <CheckBoxSharp />
                                        </ListItemIcon>
                                        {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                        Check based Ref ID
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleRtol}>
                            <ListItemIcon>
                                <Send />
                            </ListItemIcon>
                            <ListItemText primary="RTOL" />
                            {openRtol ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openRtol} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/rtol">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FormatListBulleted />
                                        </ListItemIcon>
                                        List Transaksi
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/check-rtol-ref-id">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <CheckBoxSharp />
                                        </ListItemIcon>
                                        {/* <LinkWithQuery to="/bi-fast">List Transaksi</LinkWithQuery> */}
                                        Check RTOL Ref ID
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleVA}>
                            <ListItemIcon>
                                <Send />
                            </ListItemIcon>
                            <ListItemText primary="Virtual Account" />
                            {openVA ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openVA} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/va">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FormatListBulleted />
                                        </ListItemIcon>
                                        List Transaksi
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                        </Collapse>
                        <ListItemButton onClick={handleFlip}>
                            <ListItemIcon>
                                <Send />
                            </ListItemIcon>
                            <ListItemText primary="FLIP" />
                            {openFlip ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openFlip} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <LinkWithQuery className='text-plain' to="/flip">
                                    <ListItemButton sx={{ pl: 4 }}>
                                        <ListItemIcon>
                                            <FormatListBulleted />
                                        </ListItemIcon>
                                        List Transaksi
                                    </ListItemButton>
                                </LinkWithQuery>
                            </List>
                        </Collapse>
                        <LinkWithQuery className='text-plain' to="/dispute-transaction">
                            <ListItemButton>
                                <ListItemIcon>
                                    <SafetyCheckIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dispute Transaction" />
                            </ListItemButton>
                        </LinkWithQuery>
                        <LinkWithQuery className='text-plain' to="/mutation">
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddCardRounded />
                                </ListItemIcon>
                                <ListItemText primary="Mutasi" />
                            </ListItemButton>
                        </LinkWithQuery>
                        <LinkWithQuery className='text-plain' to="/refund">
                            <ListItemButton>
                                <ListItemIcon>
                                    <ReceiptLongIcon />
                                </ListItemIcon>
                                <ListItemText primary="Refund" />
                            </ListItemButton>
                        </LinkWithQuery>
                    </>
                ) : (
                    <>
                        <div>Silahkan daftarkan Bisnis anda</div>
                        <LinkWithQuery className='text-plain' to="/topup">
                            <ListItemButton>
                                <ListItemIcon>
                                    <AddCardRounded />
                                </ListItemIcon>
                                <ListItemText primary="Top Up" />
                            </ListItemButton>
                        </LinkWithQuery>
                    </>
                )
            }
        </div>
    )
}
